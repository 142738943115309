// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => action.payload,
        
    },
});

export const { setUser,setHasChatAccount, setHasUnreadMessages} = userSlice.actions;

export default userSlice.reducer;