import { API_HOST } from "../config/config";

export const updateAmountAndText = async (contact, amount, comment, authToken, providerUserId) => {
    const response = await fetch(`${API_HOST}/leads/updateAmountAndText`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ ...contact, amount, comment, providerUserId}),
    });
  
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  
    return response.json();
  };

export const updateLeadFilledStatus = async (contact, filled, authToken, providerUserId) => {
    const response = await fetch(`${API_HOST}/leads/updateLeadFilledStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ ...contact, filled,providerUserId }),
      });
    
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    
      return response.json();
}