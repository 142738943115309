import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import MainPage from "./MainPage";
import Messages from "../messages/Messages";
import ContactsTable from "../contacts/ContactsTable";
import { useDispatch } from "react-redux";
import { setUser } from "../redux_slices/userSlice";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    position: "relative",
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "linear-gradient(to bottom, #93c5fd, #ffffff)",
  boxShadow: theme.shadows[4],
  padding: "16px 32px",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight() {
  const MENU_TITLES = {
    OPEN_CONTACTS: "לידים פתוחים",
    CLOSED_CONTACTS: "לידים סגורים",
  };
  const theme = useTheme();
  const isMobile = window.innerWidth <= 768; // You can adjust the width threshold as needed
  const [open, setOpen] = React.useState(!isMobile);
  const [item, setItem] = React.useState({
    text: "לידים חדשים",
    icon: <InboxIcon />,
    component: <ContactsTable isOpenCasesWindow={true} title={MENU_TITLES.OPEN_CONTACTS} />,
  });
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    { text: MENU_TITLES.OPEN_CONTACTS, icon: <InboxIcon />, component: <ContactsTable isOpenCasesWindow={true} title={MENU_TITLES.OPEN_CONTACTS}/> },
    { text: "כל השיחות", icon: <MailIcon />, component: <Messages /> },
    { text: MENU_TITLES.CLOSED_CONTACTS, icon: <InboxIcon />, component: <ContactsTable isOpenCasesWindow={false} title={MENU_TITLES.CLOSED_CONTACTS}/> },
  ];

  const menuItems2 = [
    { text: "הגדרות", icon: <MailIcon />, component: <Messages /> },
    {
      text: "התנתק",
      icon: <InboxIcon />,
      component: <ContactsTable isOpenCasesWindow={false}/>,
      action: "signout",
    },
  ];

  const handleMenuItemClick = (item) => {
    if (item.action === "signout") {
      signout();
    }
    setItem(item);
  };

  const signout = () => {
    localStorage.removeItem("authToken");
    dispatch(setUser(null));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <Link
              to="/"
              className=" text-3xl font-semibold flex"
              style={{ fontFamily: "Pacifico, sans-serif" }}
            >
              <span className="text-red-500">B</span>
              <span className="text-yellow-500">i</span>
              <span className="text-green-500">d</span>
              <span className="text-blue-500">m</span>
              <span className="text-indigo-500">e</span>
              <span>&nbsp;</span>
              <span className="text-red-500">P</span>
              <span className="text-yellow-500">r</span>
              <span className="text-green-500">o</span>
              <span className="text-blue-500">v</span>
              <span className="text-indigo-500">i</span>
              <span className="text-red-500">d</span>
              <span className="text-yellow-500">e</span>
              <span className="text-green-500">r</span>
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        {React.cloneElement(item.component, { key: item.text })}
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem key={menuItem.text} disablePadding>
              <ListItemButton onClick={() => handleMenuItemClick(menuItem)}>
                <ListItemText primary={menuItem.text} />
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {menuItems2.map((menuItem, index) => (
            <ListItem key={menuItem.text} disablePadding>
              <ListItemButton onClick={() => handleMenuItemClick(menuItem)}>
                <ListItemText primary={menuItem.text} />
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
