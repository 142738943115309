// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import providerReducer from './providerSlice'; // Import providerSlice

export default configureStore({
    reducer: {
        user: userReducer,
        provider: providerReducer, // Add providerReducer to your store
    },
});