import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./redux_slices/userActions";
import { setUser } from "./redux_slices/userSlice";

import MainPage from "./mainPage/MainPage";
import {
  setProvider,
  setProviderChatSecret,
} from "./redux_slices/providerSlice";
import { fetchChatSecret, fetchProviderByUserId } from "./redux_slices/providerActions";
import PersistentDrawerRight from "./mainPage/PersistentDrawerRight";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const user = useSelector((state) => state.user); // Retrieve the user from the Redux store
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = () => {
      setIsLoggedIn(false);
      setUserName("");
    };

    const handleLogin = () => {
      if (user) {
        setIsLoggedIn(true);
        setUserName(user.firstName);
      } else {
        setIsLoggedIn(false);
        setUserName("");
      }
    };

    if (user && !isLoggedIn) {
      handleLogin(user);
      return;
    }
    const authToken = localStorage.getItem("authToken");
    if (!user && authToken) {
      // Check for an existing auth token
      const fetchUserData = async () => {
        //
        const data = await fetchUser(authToken);
        if (data && data.data && data.data.user) {
          dispatch(setUser(data.data.user));
          const providerAction = await fetchProviderByUserId(
            data.data.user.userId
          );
          dispatch(setProvider(providerAction));
          const chatSecret = await fetchChatSecret(providerAction.providerId);
          dispatch(setProviderChatSecret(chatSecret.data));
        }
      };
      fetchUserData();
    } else if (!user) {
      handleLogout();
    }
  }, [dispatch, user, isLoggedIn]);

  return (
    <Router>
      <Header isLoggedIn={isLoggedIn} userName={userName} />
      <Routes>
        {isLoggedIn ? <Route path="/" element={<PersistentDrawerRight />} /> : null}
      </Routes>
    </Router>
  );
}

export default App;
