import React, { useEffect, useState } from "react";
import { Contact } from "./Contact";
import { ChatEngine, ChatList } from "react-chat-engine";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_HOST } from "../config/config";

const ContactsTable = ({ isOpenCasesWindow, title}) => {
  const [contacts, setContacts] = useState([]);
  const [dummyState, setDummyState] = useState(1);
  const [activeContactChat, setActiveContactChat] = useState(null);
  const provider = useSelector((state) => state.provider);
  const user = useSelector((state) => state.user);

  const handleContactUpdate = () => {
    // Trigger a re-render by updating the state
    setDummyState(dummyState + 1);
  };

  const handleChatWithMe = (id) => {
    console.log("chat id clicked is " + id);
    if (activeContactChat === id) setActiveContactChat(null);
    else {
      setActiveContactChat(id);
    }
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(`${API_HOST}/leads`, {
          params: { providerId: provider.providerId, providerUserId: user.userId },
          headers: { Authorization: `Bearer ${authToken}` },  
        });
        if (response.data) setContacts(response.data.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    if (provider) fetchContacts();
  }, [provider]);

  const CustomChatList = ({ chatAppState }) => {
    return (
      <div style={{ display: "none" }}>
        <ChatList {...chatAppState} />
      </div>
    );
  };

  const renderChatList = (chatAppState, chatId) => {
    console.log("chatAppState", chatAppState);
    chatAppState.setActiveChat(chatId);
    return <CustomChatList chatAppState={chatAppState} />;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <h3 style={{ display: "flex", justifyContent: "flex-end" }}>
          {title}
        </h3>
      </div>{" "}
      {contacts &&
        contacts.map((contact) => {
          if (
            (isOpenCasesWindow && !contact.filled) ||
            !contact.amount ||
            contact.amount === 0 ||
            (!isOpenCasesWindow && contact.filled && contact.amount > 0)
          ) {
            return (
              <div key={contact.id} style={{ width: "80%", margin: "0 auto" }}>
                <Contact
                  contact={contact}
                  handleChatWithMe={handleChatWithMe}
                  handleContactUpdate={()=>handleContactUpdate()}
                />
                {activeContactChat &&
                  activeContactChat === contact.chatId &&
                  provider &&
                  provider.email &&
                  provider.chatSecret && (
                    <ChatEngine
                      projectID="251fedc0-15c7-42d8-b421-1503ad33499e"
                      userName={provider.chatusername}
                      userSecret={provider.chatSecret}
                      renderChatList={(chatAppState) =>
                        renderChatList(chatAppState, contact.chatId)
                      }
                      hideNewChatButton={true}
                      height="50vh"
                      renderNewChatForm={(creds) => {}}
                      renderPeopleSettings={(creds, chat) => {}}
                      renderOptionsSettings={(creds, chat) => {}}
                      disabled={true}
                    />
                  )}
              </div>
            );
          }
          return <div />;
        })}
    </div>
  );
};
export default ContactsTable;
