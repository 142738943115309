// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const providerSlice = createSlice({
    name: 'provider',
    initialState: null,
    reducers: {
        setProvider: (state, action) => {
            console.log('Current state:', state); // Log current state
            console.log('Action payload:', action.payload); // Log action payload
            return action.payload;
        },
        setProviderHasChatAccount: (state, action) => {
            if (state) {
                state.hasChatAccount = action.payload;
            }
        },
        setProviderChatSecret: (state, action) => {
            if (state) {
                state.chatSecret = action.payload;
            }
        },
    },
});

export const { setProvider,setProviderHasChatAccount, setProviderChatSecret } = providerSlice.actions;

export default providerSlice.reducer;