import { API_HOST } from '../config/config';

export const fetchUser = async (authToken) => {

  try {
    const response = await fetch(`${API_HOST}/providerloginWithToken`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      const base64Token = btoa(data.data.token); // Encode the token in base64
      localStorage.setItem("authToken", base64Token);
      return data;
    }
    else{
      localStorage.removeItem('authToken');
      console.error('failed fetching user:', response.status);

    }
  } catch (error) {
    console.error('Error fetching user:', error);
    localStorage.removeItem('authToken');
  }
  return null;
};

export const loginUser = async (email, password) => {
  const response = await fetch(`${API_HOST}/providerLogin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: email, password }),
  });

  const data = await response.json();

  if (response.ok) {
    const base64Token = btoa(data.data.token); // Encode the token in base64
    localStorage.setItem("authToken", base64Token);
  }
  return { result: data.data, statusCode: response.status };
  
};