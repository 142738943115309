import avatar from "../images/avatar.png";
import React, { useState } from "react";
import ChatIcon from "@mui/icons-material/Chat"; // Import the ChatIcon
import { CSSTransition } from "react-transition-group";
import "./Contact.css";
import { API_HOST } from "../config/config";
import {
  updateAmountAndText,
  updateLeadFilledStatus,
} from "../fetch/contactUpdate";
import { useSelector } from "react-redux";

// Define the Contact component
export const Contact = ({ contact, handleChatWithMe, handleContactUpdate }) => {
  const [dealClicked, setDealClicked] = useState(null); // null, 'deal', 'noDeal'
  const [amount, setAmount] = useState(contact.amount);
  const [comment, setComment] = useState(contact.comment);

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const user = useSelector((state) => state.user);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!amount || !comment) {
  //     return;
  //   }

  //   // Call the backend with the contact object
  //   fetch(`${API_HOST}/leads/updateAmountAndText`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ ...contact, amount, comment }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Success:", data);
  //       setSaved(true);
  //       contact.filled = true;
  //       handleContactUpdate();
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || !comment) {
      return;
    }
    const authToken = localStorage.getItem("authToken");
    try {
      const data = await updateAmountAndText(
        contact,
        amount,
        comment,
        authToken,
        user.userId
      );
      console.log("Success:", data);
      contact.filled = true;
    } catch (error) {
      console.error("Error:", error);
    }
    handleContactUpdate();
  };

  const handleBackToOpenedLeads = async (e) => {
    try {
      const authToken = localStorage.getItem("authToken");

      const data = await updateLeadFilledStatus(
        contact,
        false,
        authToken,
        user.userId
      );
      console.log("Success:", data);
      contact.filled = false;
    } catch (error) {
      console.error("Error:", error);
    }
    handleContactUpdate();
  };

  return (
    <div
      className="p-2 m-2 rounded-lg shadow-md"
      style={{ background: "lightyellow", direction: "rtl" }}
    >
      <div className="flex items-center justify-end">
        {contact.filled && (
          <button
            onClick={handleBackToOpenedLeads}
            className="text-blue-500 hover:underline"
          >
            חזרה ללידים פתוחים
          </button>
        )}
        <button
          onClick={() => handleChatWithMe(contact.chatId)}
          title="! דבר איתי"
          className="mr-4"
        >
          <ChatIcon className="w-6 h-6" style={{ color: "blue" }} />
        </button>
      </div>
      <div className="flex items-center mb-2">
        <img
          src={avatar}
          alt="Avatar"
          className="mr-2"
          style={{ width: "30px", height: "30px" }}
        />
        <div className="flex flex-col items-start mb-2">
          <span className="font-bold">{contact.userFirstName}</span>
          <span>{formatDate(contact.date / 1000)}</span>
        </div>
      </div>

      <div className="flex items-center mb-4">
        <button
          style={{
            background: "green",
            marginRight: "5px",
            marginLeft: "5px",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            direction: "rtl",
            width: "50%",
          }}
          onClick={() => setDealClicked("deal")}
        >
          יש עסקה !
        </button>
        <button
          style={{
            background: "red",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            direction: "rtl",
            width: "50%",
          }}
          onClick={() => setDealClicked("noDeal")}
        >
          אין עיסקה ...
        </button>
      </div>

      <CSSTransition
        in={dealClicked !== null}
        timeout={300}
        classNames="detail"
        unmountOnExit
      >
        <div>
          {dealClicked === "deal" ? (
            <div>
              <div className="mb-2">
                <label htmlFor="cost">סכום העיסקה:</label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  min="0"
                  id="cost"
                  name="cost"
                  required
                  style={{ marginLeft: "100px" }}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="text">הערות:</label>
                <textarea
                  id="text"
                  name="text"
                  rows="4"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-2">
                <label htmlFor="options" className="block mb-1">
                  סיבה:
                </label>
                <select
                  id="options"
                  name="options"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="noContact">לקוח לא יצר קשר</option>
                  <option value="badTiming">זמן לא מתאים</option>
                  <option value="wrongArea">אזור לא מתאים</option>
                  <option value="priceIssue">מחיר לא מתאים</option>
                  <option value="notRelevant">לא רלוונטי</option>
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="text">הערות:</label>
                <textarea
                  id="text"
                  name="text"
                  rows="4"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )}
          <div className="mb-2" style={{ textAlign: "left" }}>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSubmit}
              style={{ marginLeft: "10px" }} // Add margin to the right of the "שמור" button
            >
              שמור
            </button>
            <button
              type="button"
              onClick={() => setDealClicked(null)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              סגור
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
