import React, { useEffect } from 'react';
import { ChatEngine } from 'react-chat-engine';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../redux_slices/userActions';
import { setUser } from '../redux_slices/userSlice';
import { fetchChatSecret, fetchProvider } from '../redux_slices/providerActions';
import { setProvider, setProviderChatSecret } from '../redux_slices/providerSlice';

const Messages = () => {
    const provider = useSelector((state) => state.provider);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if(authToken){
            const fetchData = async () => {
                if (!user) {
                    // Check for an existing auth token
                    const data = await fetchUser(authToken);
                    if (data && data.data && data.data.user) {
                        dispatch(setUser(data.data.user));
                        const providerAction = await fetchProvider(data.data.user.userId);
                        dispatch(setProvider(providerAction));
                        const chatSecret = await fetchChatSecret(providerAction.providerId);
                        dispatch(setProviderChatSecret(chatSecret.data));
                    }
                } else if (!provider) {
                    const providerAction = await fetchProvider(user.userId);
                    dispatch(setProvider(providerAction));
                    const chatSecret = await fetchChatSecret(providerAction.providerId);
                    dispatch(setProviderChatSecret(chatSecret.data));
                }
            };
            fetchData();
        }        
    }, 
    [user, dispatch, provider]);

    return (
        <div>
            <h1>All Messages</h1>
            {provider && provider.chatSecret && 
                <ChatEngine
                    projectID="251fedc0-15c7-42d8-b421-1503ad33499e"
                    userName={provider.chatusername}
                    userSecret={provider.chatSecret}

                    hideNewChatButton={true}
                    height="50vh"
                    renderNewChatForm={(creds) => {}}
                    renderPeopleSettings={(creds, chat) => {}}
                    renderOptionsSettings={(creds, chat) => {}}
                    disabled={true}
                    
                />
            }       
        </div>
    );
};

export default Messages;